<template>
  <div>
    <div class="white-content-bg" style="height: 50px"></div>
    <div class="white-content topic-page" style="padding-top: 1px;">
      <div id="styledElement">
        <b-container id="resizeElement" style="padding: 0">
          <b-row>
            <b-col>
              <div class="video-box facts-video-box facts-quiz-box">
                <span class="green-bg"></span>
                <div class="blue-bg">
                  <span class="corner-left"></span>
                  <span class="corner-right"></span>
                  <div>
                    <div class="facts-pagination">
                      <ul class="quiz-score">
                        <li v-for="(q, k) in questions" :key="k">
                          <i v-if="correctAnswers.includes(k)" class="q-corect"></i>
                          <i v-if="wrongAnswers.includes(k)" class="q-wrong"></i>
                        </li>
                      </ul>
                    </div>
                    <b-row class="custom-row">
                      <b-col class="quiz-number">
                        <div class="quiz-holder">
                          <div class="rounded-circle">{{ currentQuestionIdx + 1 }}</div>
                          <img v-if="currentQuestion.question_image.length" :src="currentQuestion.question_image">
                          <img v-else src="../assets/img/quiz-img.png">
                        </div>
                      </b-col>
                      <b-col class="quiz-q">
                        <perfect-scrollbar :options="{ suppressScrollX: true }" class="q-holder">
                          <h5>{{ currentQuestion.question }}</h5>
                        </perfect-scrollbar>
                      </b-col>
                    </b-row>
                        <div>
                          <ul class="quiz-answer-list">
                            <li v-for="(answer, key) in currentQuestion.answers" :key="key" :class="{ active: key === currentQuestionAnswer && !answered }">
                              <b-form-radio :disabled="answered" v-model="currentQuestionAnswer" :value="key">
                                <!-- <span :class="{ correct: (answered && currentQuestionAnswer === key && answer.correct), incorrect: (answered && currentQuestionAnswer === key && !answer.correct) }">{{ answer.answer }}</span> -->
                                <span :class="{ correct: (answered && answer.correct), incorrect: (answered && currentQuestionAnswer === key && !answer.correct) }">{{ answer.answer }}</span>
                              </b-form-radio>
                            </li>
                          </ul>
                          <b-btn v-if="answered && (this.currentQuestionIdx < this.questions.length - 1)" @click="goToNextQuestion" class="next-q-btn"></b-btn>
                          <b-btn v-if="!answered" @click="checkAnswer()" :disabled="currentQuestionAnswer === null">Check Answer</b-btn>
                          <div v-if="answered">
                            <b-btn v-if="!(this.currentQuestionIdx < this.questions.length - 1)" @click="endGame">
                              Get Score
                            </b-btn>
                          </div>
                        </div>
                      <div class="quiz-image-holder">
                        <img v-if="currentQuestion.question_image.length" :src="currentQuestion.question_image">
                        <img v-else src="../assets/img/quiz-img.png">
                      </div>
                      <div class="clearfix"></div>

                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import questions from '../assets/quiz.json'

export default {
  name: 'Quiz',
  data () {
    return {
      currentQuestionIdx: 0,
      currentQuestionAnswer: null,
      answered: false,
      correctAnswers: [],
      wrongAnswers: [],
      questions
    }
  },
  computed: {
    ...mapGetters(['userName', 'score']),
    currentQuestion () {
      return this.questions[this.currentQuestionIdx]
    },
    totalQuestions () {
      return this.questions.length
    }
  },
  methods: {
    ...mapActions(['setScore', 'resetUser']),
    checkAnswer () {
      this.answered = true
      if (this.currentQuestion.answers[this.currentQuestionAnswer].correct) {
        this.correctAnswers.push(this.currentQuestionIdx)
        this.wrongAnswers = this.wrongAnswers.filter(a => a !== this.currentQuestionIdx)
      } else {
        if (!this.wrongAnswers.includes(this.currentQuestionIdx)) {
          this.wrongAnswers.push(this.currentQuestionIdx)
        }
      }
    },
    goToNextQuestion () {
      if (this.currentQuestionIdx <= this.questions.length - 1) {
        const remaining = [...Array(this.totalQuestions).keys()].map((i) => i).filter(q => !this.correctAnswers.includes(q))
        if (remaining.length) {
          const q = remaining.filter(r => r > this.currentQuestionIdx)[0]
          if (q) {
            this.currentQuestionIdx = q
            this.currentQuestionAnswer = null
            this.answered = false
          }
        } else {
          this.currentQuestionIdx = this.questions.length - 1
          this.answered = true
        }
      }
    },
    setCurrentQuestion (q) {
      this.currentQuestionAnswer = null
      this.answered = false
      this.currentQuestionIdx = q
    },
    endGame () {
      this.setScore(this.correctAnswers.length).then(() => {
        this.$router.push({ name: 'Score' })
      })
    }
  },
  mounted () {
    if (this.userName.length === 0) {
      this.$router.push({ name: 'Avatar' })
    }
    if (this.score !== null) {
      this.resetUser()
      this.$router.push({ name: 'Avatar' })
    }
  }
}
</script>

<style scoped>
.quiz-answer-list, .quiz-score {
  list-style-type: none;
}

.correct {
  color: #00EE00;
}

.incorrect {
  color: #ff0000;
}

.quiz-score li {
  float: left;
  margin-left: 10px;
}

.quiz-score {
  display: flex;
  justify-content: center;
  padding-left: 0;
  font-size: 25px;
}

.quiz-answer-list .active span {
  background-color: #00AB4F;
  color: #fff;
}
</style>
